<!-- eslint-disable -->

<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>
    <div id="manufacturer">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="filteredCatalogs" :search="search" :footer-props="footerProps"
              :items-per-page="100" hide-default-footer>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title">Размещение продуктов в каталоге
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <div @click="getCategoryList" style="width: 35%">
                    <my-custom-select :options="categoryList" class="custom-v-select" v-model="filterCategory"
                      @change="handleFilterClick" placeholder="Все категории">
                      <!-- eslint-disable-next-line vue/no-unused-vars  -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Нет результатов для <em>{{ search }}</em>
                        </template>
                        <em v-else style="opacity: 0.5">Идет загрузка...</em>
                      </template>
                    </my-custom-select>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                    <input type="search" class="search-component__input mr-10" v-model="search"
                      append-icon="mdi-magnify" placeholder="Поиск" single-line hide-details />
                    <svg width="17" height="17" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <!-- <v-text-field
                        class="mr-10"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Поиск"
                        single-line
                        hide-details
                      ></v-text-field> -->
                  <!-- <button id="staff-add-button" @click="createItem">
                        Создать новый ресторан +
                      </button> -->
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        {{
          isEditing === true
            ? "Редактирование"
            : "Размещение продукта"
        }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-3">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Название</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col @click="getProductList" cols="8" class="px-0 py-0">
                              <my-custom-select v-model="selectedProduct" :options="productList"
                                class="custom-v-select">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    Нет результатов для <em>{{ search }}</em>
                                  </template>
                                  <em v-else style="opacity: 0.5">Идет загрузка...</em>
                                </template>
                                <template #spinner="{ loading }">
                                  <div v-if="loading" style="
                                      border-left-color: rgba(
                                        88,
                                        151,
                                        251,
                                        0.71
                                      );
                                    " class="vs__spinner">
                                    The .vs__spinner class will hide the text
                                    for me.
                                  </div>
                                </template>
                              </my-custom-select>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pb-0 pt-3 pb-3">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Номер в списке</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <!-- <span class="red-dot">*</span> -->
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <input class="col-description" v-model="sortOrderProduct" :placeholder="issortOrderProductFocused
          ? ''
          : textareaPlaceholder
          " @focus="issortOrderProductFocused = true" @blur="issortOrderProductFocused = false"
                                @input="handleInput" />
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pt-3 pb-2">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Категория</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0" @click="getCategoryList">
                              <my-custom-select :options="categoryList" v-model="selectedCategory"
                                class="custom-v-select">
                                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                    Нет результатов для <em>{{ search }}</em>
                                  </template>
                                  <em v-else style="opacity: 0.5">Идет загрузка...</em>
                                </template>
                              </my-custom-select>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div :class="isEditing === true
          ? 'btn-container'
          : 'btn-container-1 px-6'
          ">
                        <div v-if="isEditing" class="delete-btn" text @click="deleteProductCategoryConfirm">
                          Удалить
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div :class="isEditing === true ? 'save-btn' : 'save-btn ml-7'
          " text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="780px">
                    <v-card>
                      <v-card-title class="warning-title">Вы точно хотите удалить данный продукт из категории?
                        <v-btn icon @click="closeDelete">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-actions class="warning-margin">
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div class="del-btn" text @click="deleteProductCategoryConfirm">
                          Удалить
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr style="cursor: pointer">
                  <td @click="getProductCategory(item)">
                    {{ item.product.name || " " }}
                  </td>
                  <td @click="getProductCategory(item)">
                    {{ item.category.name || " " }}
                    <!-- {{ item.categories.length > 0 ? item.categories[0].name : " " }} -->
                  </td>
                  <td @click="getProductCategory(item)">
                    {{ item.sortOrder || " " }}
                  </td>
                  <!-- <td @click="getCategory(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getCategory(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getCategory(item)">
                    {{ item.createdBy.fullName }}
                    <span v-for="(localization, index) in item.localizations" :key="index">
                          {{ localization.locale }}: {{ localization.name }}<br>
                      </span>
                  </td>
                  <td @click="getCategory(item)">
                    {{ getStatusTranslation(item.stateCode) }}
                  </td> -->
                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index, $event)">mdi-dots-vertical</v-icon>
                    <div class="ActionMenu" v-if="showActionMenu[index]" :ref="'ActionMenu' + index">
                      <div @click="getProductCategory(item)" class="ActionMenu-item-1">
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        Удалить
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <div v-if="showNoDataMessage">Нет подходящих записей.</div>
                <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
              </template>
              <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во элементов на странице:
                    <v-select class="custom-select" v-model="itemsPerPage" :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage" hide-details></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button type="button" :disabled="currentPage === 1"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                      @click="goToPreviousPage">
                      <span class="v-btn__content">
                        <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button type="button" :disabled="currentPage === totalPages"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === totalPages }" aria-label="Следующая страница"
                      @click="goToNextPage">
                      <span class="v-btn__content">
                        <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { debounce } from 'lodash';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  directives: { mask },
  name: "Catalog",
  components: {
    "my-custom-select": vSelect,
  },
  data: () => ({
    showNoDataMessage: false,
    showActionMenu: [],
    filterCategory: null,
    selectedProduct: null,
    selectedCategory: null,
    productList: [],
    categoryList: [],
    isHovered: false,
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    issortOrderProductFocused: false,
    iscategoryNameEnFocused: false,
    isorderNumFocused: false,
    selectedRoles: null,
    sortOrderProduct: "",
    categoryNameEn: "",
    orderNum: "",
    dialogDelete: false,
    dialogCreate: false,
    currentState: "",
    search: "",
    loading: false,
    snackbar: false,
    message: "",
    statusTranslations: {
      Active: "Активный",
      Inactive: "Неактивный",
    },
    newProductToCategory: {},
    category: {},
    selectedType: null,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "product.name",
      },
      { text: "Категория", value: "category.name" },
      { text: "Номер в списке", value: "sortOrder" },
      //   { text: "Дата изменения", value: "modifiedOn" },
      //   { text: "Пользователь", value: "createdBy.fullName" },
      //   { text: "Статус", value: "stateCode" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    catalog: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    isEditing: false,
    currentItem: "",
  }),

  created() {
    this.initialize();
    // this.profileInfo();
    document.addEventListener("click", this.closeActionMenu);
  },
  computed: {
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    filteredCatalogs() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectOption) {
        // console.log(this.filterCategory.label);
        return this.catalog.items;
        // .filter(item => item.category.id === this.filterCategory.value);
      } else {
        return this.catalog.items;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, 100], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    document.addEventListener("click", this.closeActionMenu);

    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeActionMenu);

    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

  methods: {
    handleInput() {
      // Оставить только цифры
      this.sortOrderProduct = this.sortOrderProduct.replace(/\D/g, "");
    },
    toggleActionMenu(index, event) {
      event.stopPropagation(); // Остановить распространение события, чтобы оно не закрыло меню
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      let clickedOutside = true;
      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const menuRef = this.$refs["ActionMenu" + i];
          if (menuRef && menuRef.contains(event.target)) {
            clickedOutside = false;
            break;
          }
        }
      }
      if (clickedOutside) {
        for (let i = 0; i < this.showActionMenu.length; i++) {
          this.$set(this.showActionMenu, i, false);
        }
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getStatusTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "sortOrderProduct") {
        this.issortOrderProductFocused = true;
      } else if (inputName === "categoryNameEn") {
        this.iscategoryNameEnFocused = true;
      }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.sortOrderProduct = "";
      this.selectedProduct = {};
      this.selectedCategory = {};
      this.isEditing = false;
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editProductToCategory();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewProductToCategory();
      }
    },
    initialize() {
      this.$http
        .put("/api/ProductCategory/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          // filters: [
          //   {
          //     property: "product.name",
          //     operator: "contains",
          //     value: this.search,
          //   }
          // ],
          sorting: {
            property: "CreatedOn",
            isDescending: true,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.catalog = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
        })
        .catch((error) => {
          this.error = error
          if (this.totalItems === 0) {
            this.showNoDataMessage = true;
          } else {
            this.showNoDataMessage = false;
          }
        });
    },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        if (this.filterCategory === null) {
          this.initialize();
        } else this.handleFilterClick();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        if (this.filterCategory === null) {
          this.initialize();
        } else this.handleFilterClick();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1;
      // this.initialize();
      if (this.filterCategory === null) {
        this.currentPage = 1;
        this.initialize();
      } else {
        this.currentPage = 1;
        this.handleFilterClick();
      }
    },
    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredCatalogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentItem = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$http
        .delete(`/api/Employee/${this.editedItem.id}`)
        .then(() => {
          this.staffs.items.splice(this.editedIndex, 1);
          this.closeDelete();

          // Здесь мы устанавливаем флаг showSnackbar в true,
          // чтобы уведомление показалось после завершения удаления
          this.snackbar = true;
          this.message = "Сотрудник успешно удален";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка удаления сотрудника";
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.sortOrderProduct = null;
      this.selectedProduct = {};
      if (!this.filterCategory || !this.filterCategory.value) {
        this.selectedCategory = {};
        console.log(this.filterCategory);
      }
      else {
        this.selectedCategory = this.filterCategory;
        console.log(this.filterCategory);
      }
      // this.selectedCategory = {};
      // this.getEmployee();
    },

    closeCreate() {
      this.dialogCreate = false;
      this.sortOrderProduct = "";
      this.isEditing = false;
      this.selectedProduct = {};
      this.selectedCategory = {};
    },

    createNewProductToCategory() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //     this.selectedOption
      //   );
      // if (!this.filterCategory || !this.filterCategory.value) {

      //   (this.newProductToCategory.categoryId = this.selectedCategory.value)
      //   this.selectedCategory = this.filterCategory.label;
      //   console.log(this.filterCategory);
      // }
      // else {
      //   this.selectedCategory = this.filterCategory.label;
      //   console.log(this.filterCategory);
      //   (this.newProductToCategory.categoryId = this.filterCategory.value)
      // }
      (this.newProductToCategory.productId = this.selectedProduct.value),
        // (this.newEmp.mobilePhone = this.phone),
        (this.newProductToCategory.categoryId = this.selectedCategory.value),
        (this.newProductToCategory.sortOrder = this.sortOrderProduct);
      this.$http
        .post("/api/ProductCategory", this.newProductToCategory)
        .then(() => {
          this.handleFilterClick();
          this.closeCreate();
          this.newProductToCategory = {
            productId: "",
            categoryId: "",
            sortOrder: "",
            //   employeeRole: null,
          };
          this.snackbar = true;
          this.message = "Продукт успешно добавлен";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка добавление продукта";
        });
    },
    getProductCategory(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.createItem();
      this.isEditing = true;
      this.editedIndex = this.filteredCatalogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentItem = item.id;
      this.$http
        .get(`/api/ProductCategory/${item.id}`)
        .then((response) => {
          this.category = response.data;
          this.sortOrderProduct = this.category.sortOrder;
          this.selectedCategory = this.category.category.name;
          this.selectedProduct = this.category.product.name;
          // this.selectedCategory.value = this.category.category.id;
          // this.selectedProduct.value = this.category.product.id;
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleFilterClick() {
      if (!this.filterCategory || !this.filterCategory.value) {
        this.currentPage = 1;
        this.initialize();
        return;
      }
      this.$http
        .put("/api/ProductCategory/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          filters: [
            {
              property: "CategoryId",
              operator: "eq",
              value: this.filterCategory.value,
            },
            {
              property: "product.name",
              operator: "contains",
              value: this.search,
            }
          ],
          sorting: {
            property: "CreatedOn",
            isDescending: true,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.catalog = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
          if (this.totalItems === 0) {
            this.showNoDataMessage = true;
          } else {
            this.showNoDataMessage = false;
          }
        })
        .catch((error) => (this.error = error));
    },
    editProductToCategory() {
      this.newProductToCategory = {};
      if (this.selectedProduct && this.selectedProduct.value) {
        this.category.productId = this.selectedProduct.value;
      } else {
        // Если selectedProduct не определен или его значение пусто, выполните дополнительные действия или установите другие значения по умолчанию.
        // Например:
        this.category.productId =
          this.category.product.id; /* значение по умолчанию */
      }

      if (this.selectedCategory && this.selectedCategory.value) {
        this.category.categoryId = this.selectedCategory.value;
      } else {
        // Аналогично для selectedCategory
        this.category.categoryId =
          this.category.category.id; /* значение по умолчанию */
      }
      this.category.sortOrder = this.sortOrderProduct;
      // this.category.productId = this.selectedProduct.value;
      // this.category.categoryId = this.selectedCategory.value;
      this.$http
        .put(`/api/ProductCategory`, this.category, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (this.filterCategory === null) {
            this.initialize();
          } else {
            this.handleFilterClick();
          }
          this.closeCreate();
          this.category = response.data;
          console.log(this.category);
          // this.newProductToCategory = {
          //   productId: "",
          //   categoryId: "",
          // };
          this.snackbar = true;
          this.message = "Cвязка успешно изменена";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.message = "Ошибка редактирования элемента";
        });
    },
    deleteProductCategoryConfirm() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .delete(`/api/ProductCategory/${this.currentItem}`)
        .then(() => {
          this.filteredCatalogs.splice(this.currentItem, 1);
          this.dialogCreate = false;
          this.closeDelete();
          if (this.filterCategory === null) {
            this.initialize();
          } else {
            this.handleFilterClick();
          }
          this.snackbar = true;
          this.message = "Продукт успешно удален из категории";
        })
        .catch((error) => {
          alert(error);
          this.snackbar = true;
          this.message = "Ошибка удаления";
        });
    },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    // handleClickOutside(event) {
    //   const inputButton = this.$refs.inputButton;
    //   const inputMenu = this.$refs.inputMenu;

    //   if (
    //     inputButton &&
    //     inputMenu &&
    //     !inputButton.contains(event.target) &&
    //     !inputMenu.contains(event.target)
    //   ) {
    //     this.showInputMenu = false;
    //   }
    // },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
    getProductList() {
      this.loading = true;
      this.$http
        .put(`/api/Product/PagedList`, {
          //     params: {
          // pageNumber: this.currentPage,
          // pageSize: this.itemsPerPage,
          //   },
          pageNumber: this.currentPage,
          pageSize: 100,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
          this.productList = response.data.items.map((item) => ({
            label: item.name,
            value: item.id,
          })); // Получаем массив элементов
          // this.currentPage = response.data.pageNumber; // Текущая страница
          this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
          console.log(this.productList);
          this.loading = false;
          // this.hasNextPage = response.data.hasNextPage;
          // this.hasPreviousPage = response.data.hasPreviousPage;
          // // Возможно, также нужно обработать значения hasNextPage и hasPreviousPage в вашем компоненте.
        })
        .catch((error) => (this.error = error));
    },
    getCategoryList() {
      this.loading = true;
      this.$http
        .get(`/api/Category/GetList`, {
          //     params: {
          // pageNumber: this.currentPage,
          // pageSize: this.itemsPerPage,
          //   },
          pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // В данном случае, предполагается, что response.data содержит объект с информацией о пагинации и массивом элементов.
          this.categoryList = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          })); // Получаем массив элементов
          // this.currentPage = response.data.pageNumber; // Текущая страница
          this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
          console.log(this.categoryList);
          this.loading = false;
          // this.hasNextPage = response.data.hasNextPage;
          // this.hasPreviousPage = response.data.hasPreviousPage;
          // // Возможно, также нужно обработать значения hasNextPage и hasPreviousPage в вашем компоненте.
        })
        .catch((error) => (this.error = error));
    },
  },

  watch: {
    categoryName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("categoryNameTextarea");
      });
    },
    filterCategory() {
      // Вызываем метод initialize при изменении filterCategory
      if (this.filterCategory === null) {
        this.initialize();
      } else this.currentPage = 1;
      this.handleFilterClick();
    },
    categoryNameEn() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("categoryNameEnTextarea");
      });
    },
    search: debounce(function () {
      if (this.filterCategory === null) {
        this.initialize();
      } else {
        this.handleFilterClick();
      }
    }, 500),
  },
};
</script>

<style scoped>
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}

.warning-margin {
  margin-top: 16px;
  margin-bottom: 10px;
}

.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}

::v-deep .vs__selected {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  align-items: center;
  color: var(--vs-selected-color);
  display: flex;
  line-height: var(--vs-line-height);
  margin: 4px 2px 0;
  padding: 0 0.25em;
  z-index: 0;

  /* max-width: 100%;
  width: fit-content; */
}

::v-deep.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 1px solid transparent;
  border-left: none;
  box-shadow: none;
  color: var(--vs-search-input-color);
  flex-grow: 1;
  /* font-size: var(--vs-font-size); */
  /* line-height: var(--vs-line-height); */
  margin: 4px 0 0;
  max-width: 100%;
  outline: none;
  padding: 5px 7px;
  width: 0;
  z-index: 1;
}

::v-deep .v-application ul {
  padding-left: 0 !important;
}

::v-deep .v-application ol {
  padding-left: 0 !important;
}

.custom-v-select>>>li {
  white-space: normal;
  width: 335px;
  width: 100%;
  overflow-x: hidden;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style: none;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--05, #dae3ea);
}

.custom-v-select {
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fb5858;
  --vs-controls-color: #848484;
  --vs-border-color: #848484;
  --vs-dropdown-option-padding: 3px 15px 3px 15px;
  --vs-dropdown-bg: #fff;
  --vs-dropdown-option-color: #000;
  --vs-dropdown-max-height: 120px;
  --vs-selected-bg: #bcb648;
  --vs-selected-color: #000;
  /* --vs-dropdown-min-width: 160px; */
  --vs-search-input-color: #000;
  --vs-dropdown-option--active-bg: #bcb648;
  --vs-dropdown-option--active-color: red;
  --vs-dropdown-min-width: 100%;

}

.custom-v-select>>>.vs__dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

/* .custom-v-select >>> .vs__selected {
     display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
} */
.custom-v-select>>>.vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #848484 !important;
  border-radius: 5px;
  height: 5px;
}

.custom-v-select .vs__dropdown-menu::-webkit-scrollbar-track {
  background-color: #ddd;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-menu {
  color: var(--07, #000);
  font-weight: 400;
  text-align: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

::v-deep .vs__actions {
  align-items: center;
  display: flex;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 0px;
  padding-left: 15px;
}

::v-deep .vs__dropdown-menu {
  z-index: 100 !important;
}

::v-deep .vs__dropdown-toggle {
  /* height: 45px; */
  max-height: 5.5em;
  /* white-space: nowrap;  */
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
}

.custom-v-select>>>ul {
  padding-left: 0px !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0 0 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 22px;
}

.custom-v-select>>>li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

::v-deep .v-application ol,
.v-application ul {
  padding-left: 0 !important;
  /* Используйте !important, чтобы переопределить стиль */
}

ol,
ul {
  padding-left: 0 !important;
  /* Замените на ваше значение паддинга слева */
}

.vs__dropdown-menu {
  color: red;
}

.save-btn-paswd {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

::v-deep .v-toolbar__content {
  align-items: center !important;
}

.ActionMenu {
  position: absolute;
  /* bottom: -110px;
    left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}

.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(242,
      242,
      246,
      0.5);
  /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-top-right-radius: 10px;
  /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}

.ActionMenu-item-2 {
  border-bottom-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px;
  /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
  color: #fff;
  /* White text color */
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
  background-color: #357a38;
  /* Darker green background on hover */
}

.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0;
  /* Пример: изменение цвета фона при наведении */
  color: #333;
  /* Пример: изменение цвета текста при наведении */
  cursor: pointer;
  /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}

.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}

.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  bottom: 32%;
  left: 30.7%;
  width: 347px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}

.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}

.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 46px;
  align-items: center;
}

.btn-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
}

.red-dot-margin-2 {
  margin-left: -17px;
}

.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.text-limit {
  width: 30ch;
  /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
}

.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.col-description,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: center;
}

.role-margin {
  margin-right: 37px;
}

.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  /* min-height: 20px; */
  width: 100%;
  /* margin-left: 18px; */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: balance;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  /* margin-left: 37px; */
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.search-component {
  /* max-width: 606px; */
  width: 30vw;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 30%;
  right: 55px;
}

#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}

::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid #848484;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
